<template>
  <div
    class="view-root view-content bg-white notes-list-wrapper"
    style="box-sizing: border-box"
  >
    <div class="placeholder"></div>
    <div class="flex justify-between items-center mb-6">
      <p class="title">My Articles</p>
      <Avatar />
    </div>
    <div class="note-container">
      <div class="content-container">
        <div class="w-full flex items-center justify-between flex-row-reverse">
          <div class="custom-btn" @click="add">
            <img class="btn-icon" src="../../assets/add.png" />
            <p>New Article</p>
          </div>
        </div>
        <div
          v-for="(item, index) in tableData"
          :class="[{ firstItem: index === 0 }, 'note-item']"
          :key="index"
          @click="edit(item)"
        >
          <Article :content="item" @copy="copy(item)" />
        </div>
        <div
          v-if="tableData.length === 0"
          class="empty-container flex w-full items-center justify-center mt-6"
        >
          <img src="../../assets/empty.png" class="empty-img mb-6" />
          <p class="empty-title">
            You haven't written down any precious thoughts yet
          </p>
          <p class="empty-title mb-2">
            Let it continuously inspire you in the future
          </p>
          <div class="w-full p-3">
            <p class="empty-desc" style="color: #1a202c">
              Are you hesitant to try a new note-taking app? Here are some
              confidence boosters:
            </p>
            <p class="empty-desc">
              - Your note contents are encrypted and secured during transmission
              and storage.
            </p>
            <p class="empty-desc">
              - Easily export all your notes to PDF with the "Export" function
              conveniently located next to the ADD button in the upper right
              corner.
            </p>
            <p class="empty-desc">
              - We are dedicated to promptly addressing issues and making
              iterative improvements to enhance your experience.
            </p>
          </div>
        </div>
      </div>
      <div class="search-container">
        <p class="search-title">Find Your Articles</p>
        <p class="search-tips">Search through title, body text or tags</p>
        <div class="search-input">
          <img class="search-icon" src="../../assets/search_icon.png" />
          <el-input
            v-model="keyword"
            placeholder="Search..."
            @keyup.enter.native="onSearch"
          />
        </div>
      </div>
    </div>
    <div id="myElement" style="opacity: 0">
      <p>
        Enjoyed this passage? Just one click below to add it into your personal
        AI knowledge base and receive periodic reminders of its key takeaways
        via emails - so you get a lasting inspiration from <span style="font-style: italic;">{{ currentArticle.title }}</span> by <span style="font-style: italic;">{{ currentArticle.user_nickName || '未知'}}</span>!
      </p>
      <p>
        <a
          :href="`https://www.refff.ai/#/article-detail?id=${shareId}`"
          style="color: rgb(112, 177, 231)"
          >[Add & Inspire!] </a
        >supported by Refff.ai
      </p>
    </div>
  </div>
  <!-- <div class="article-container" v-loading="loading">
    <div
      style="
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
      "
    >
      <el-button type="primary" @click="add">Add</el-button>
    </div>
    <div v-if="tableData.length > 0">
      <div
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
        "
      >
        <div
          v-for="(card, index) in tableData"
          :key="index"
          :id="'note' + (index + 1)"
          style="cursor: pointer"
          @click="edit(card)"
        >
          <div class="notes-item">
            <p class="item-title">{{ card.title }}</p>
            <p class="item-content">
              {{ card.summary }}
            </p>
            <div style="margin-top: 14px; margin-bottom: 6px">
              <el-button type="primary" size="small" @click.stop="copy(card)"
                >Copy the Refff Link</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="empty-view"></div>
    <div id="myElement" style="opacity: 0">
      <p>
        Enjoyed this passage? Just one click below to add it into your personal
        AI knowledge base and receive periodic reminders of its key takeaways
        via emails - so you get a lasting inspiration!
      </p>
      <p>
        <a
          :href="`https://www.refff.ai/#/article-detail?id=${shareId}`"
          style="color: rgb(112, 177, 231)"
          >[Add & Inspire!] </a
        >supported by Refff.ai
      </p>
    </div>
  </div> -->
</template>

<script>
import { getMyArticles } from "../../api/reff";
import { getUserId } from "../../utils/store";
import Avatar from "../../components/Avatar.vue";
import Article from "../../components/PublisherArticle.vue";
import { Loading } from "element-ui";

export default {
  components: {
    Avatar,
    Article,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      id: "",
      shareId: "",
      keyword: "",
      currentArticle: {}
    };
  },
  mounted() {
    this.getMyArticles();
  },
  methods: {
    add() {
      this.$router.push({
        path: "/publisher/view-article",
        query: {
          id: "",
        },
      });
    },
    edit(row) {
      this.$router.push({
        path: "/publisher/view-article",
        query: {
          id: row._id.$id,
        },
      });
    },
    getMyArticles() {
      const loadingInstance = Loading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
        spinner: "el-icon-loading",
        text: "Loading data",
      });
      getMyArticles({
        user_id: getUserId(),
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data;
          }
        })
        .finally(() => {
          loadingInstance.close();
        });
    },
    onSearch(e) {
      const keyCode = window.event ? e.keyCode : e.which;

      if (keyCode === 13) {
        let loadingInstance = Loading.service({
          fullscreen: true,
          background: "rgba(0, 0, 0, 0.8)",
          spinner: "el-icon-loading",
          text: "Loading data",
        });
        getMyArticles({
          user_id: getUserId(),
        })
          .then((res) => {
            const result = res.data.data;
            if (!this.keyword) {
              this.tableData = result;
            } else {
              this.tableData = result.filter(
                (i) =>
                  i.title.includes(this.keyword) ||
                  i.raw_content.includes(this.keyword) ||
                  i.summary.includes(this.keyword)
              );
            }
          })
          .finally(() => loadingInstance.close());
      }
    },
    copy(data) {
      this.shareId = data._id.$id;
      this.currentArticle = data;
      this.$nextTick(() => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(document.getElementById("myElement"));
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        this.$message({
          message: "Copy successfully",
          type: "success",
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 54.17%);
  z-index: 100;
}
.title {
  font-family: "Poppins";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  color: #1a202c;
}
.notes-list-wrapper {
  padding: 32px;
  overflow-y: scroll;
  position: relative;
}
.custom-btn {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  color: white;
  font-family: Poppins;
  background: #ec652b;
  height: 48px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  &.export-btn {
    color: #005a64;
    border: 2px solid #005a64;
    height: 48px;
    background: white;
    .btn-icon {
      width: 20px;
      height: 20px;
    }
  }
  .btn-icon {
    width: 16px;
    height: 16px;
  }
}
.note-container {
  display: flex;
  gap: 24px;
  .content-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 16px;
    // height: calc(100vh - 106px);
    .note-item {
      width: 100%;
    }
  }
  .search-container {
    width: 360px;
    height: fit-content;
    font-family: "Poppins";
    background: #f4f4f4;
    border-radius: 12px;
    padding: 24px;
    .search-title {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      color: #1a202c;
      margin-bottom: 8px;
    }
    .search-tips {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .search-input {
      width: 100%;
      box-shadow: 4px 8px 16px 0px rgba(93, 106, 131, 0.02);
      background: #fff;
      margin: 24px 0 24px;
      border-radius: 12px;
      height: 48px;
      display: flex;
      align-items: center;
      .search-icon {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }
      &::v-deep .el-input__inner {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        background: transparent;
      }
    }
  }
}
@media (max-width: 1400px) {
  .note-container {
    flex-direction: column-reverse;
    .search-container {
      width: 100%;
    }
  }
}

.empty-container {
  flex-direction: column;
  .empty-img {
    width: 364px;
    height: 214px;
  }
  .empty-title {
    color: #1a202c;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
  }
  .empty-desc {
    color: #718096;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
</style>
