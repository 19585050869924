import { render, staticRenderFns } from "./PublisherArticle.vue?vue&type=template&id=2917b9db&scoped=true"
import script from "./PublisherArticle.vue?vue&type=script&lang=js"
export * from "./PublisherArticle.vue?vue&type=script&lang=js"
import style0 from "./PublisherArticle.vue?vue&type=style&index=0&id=2917b9db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2917b9db",
  null
  
)

export default component.exports